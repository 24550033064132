import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Spinner, Alert, Button } from 'react-bootstrap';
import { db } from '../../Firebase'; // Update import path as necessary
import { doc, getDoc } from 'firebase/firestore';
import { FaWhatsapp, FaFacebook, FaInstagram, FaTelegram } from 'react-icons/fa';


export const EntertainmentPage = () => {
    const { id, language } = useParams(); 

    const [iframeSrc, setIframeSrc] = useState(null);
    const [downloadIframeSrc, setDownloadIframeSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [overlayVisible, setOverlayVisible] = useState(true);
    const [downloadOverlayVisible, setDownloadOverlayVisible] = useState(true);
    const [movieName, setMovieName] = useState('');
     const [adData, setAdData] = useState(null);
     const [adDisplayed, setAdDisplayed] = useState(false);
     
    
    
     useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch movie details based on the selected language
                console.log('Fetching data for:', language, id);
                const docRef = doc(db, language, id); // Dynamic language
                const docSnap = await getDoc(docRef);
    
                if (docSnap.exists()) {
                    const task = docSnap.data();
                    console.log('Movie data:', task); // Log the fetched data
                    setIframeSrc(task.link);
                    setMovieName(task.name || 'Unknown Movie');
    
                    if (task.downloadlink) {
                        setDownloadIframeSrc(task.downloadlink);
                    }
                } else {
                    console.error(`No task found with id: ${id}`);
                    setError("Invalid movie ID or no details available for the selected movie.");
                }
    
                // Fetch advertisement data
                const adDocRef = doc(db, 'ads', 'ad1'); // Update this to your actual ad document path
                const adDocSnap = await getDoc(adDocRef);
                if (adDocSnap.exists()) {
                    const ad = adDocSnap.data();
                    setAdData(ad);
                } else {
                    console.error('No ad data found.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError("Error fetching data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [id, language]); // Dependency includes `id` and `language`
    

    const handleFullScreenClick = () => {
        if (!adDisplayed) {
            window.open('', '_blank'); // Replace with actual ad URL
            setAdDisplayed(true);
        } else {
            setOverlayVisible(false);
        }
    };

    const handleDownloadFullScreenClick = () => {
        if (!adDisplayed) {
            window.open('', '_blank'); // Replace with actual ad URL
            setAdDisplayed(true);
        } else {
            setDownloadOverlayVisible(false);
        }
    };

    const handleShare = (platform) => {
        const movieLink = window.location.href;

        if (platform === 'whatsapp') {
            window.open(`https://wa.me/?text=${encodeURIComponent(movieLink)}`, '_blank');
        } else if (platform === 'facebook') {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(movieLink)}`, '_blank');
        } else if (platform === 'telegram') {
            window.open(`https://telegram.me/share/url?url=${encodeURIComponent(movieLink)}&text=${encodeURIComponent("Check out this movie!")}`, '_blank');
        } else if (platform === 'instagram') {
            alert("Instagram doesn't support direct link sharing. Please share the link manually.");
        }
    };

  

    const headerStyle = {
        color: '#fff',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
        marginTop: '-25px',
        background: 'linear-gradient(180deg, #1765f5, #243b55)',
        borderRadius: '8px',
    };
    

    const iframeStyles = {
        border: 'none',
        width: '100%',
        height: '250px',
        borderRadius: '8px',
        overflow: 'hidden',
        cursor: 'pointer',
    };

    const downloadIframeContainerStyles = {
        position: 'relative',
        width: '100%',
        marginTop: '-50px',
        height: '250px',
        overflow: 'hidden',
    };

    const downloadIframeStyles = {
        border: 'none',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        zIndex: 1,
    };

    const downloadFullScreenStyles = {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '20px',
        opacity: downloadOverlayVisible ? 0.1 : 0,
        transition: 'opacity 0.3s ease-in-out',
    };

    const bannerStyles = {
        position: 'absolute',
        top: '50%',
        marginTop: '-15px',
        height: '100px',
        left: '-10px',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 9)',
        padding: '10px 20px',
        borderRadius: '5px',
        zIndex: 3,
        textAlign: 'left',
        fontWeight: 'bold',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    };
    const movieNameStyles = {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#333',
        backgroundColor: '#f8f9fa',
        padding: '15px 20px',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        margin: '10px 0',
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '1.5px',
    };

    const handleDownloadClick = () => {
        if (!adDisplayed) {
            const adElement = document.querySelector('.adsbygoogle');
            if (adElement) {
                adElement.click(); // Simulate a user action on the ad
            }
            setAdDisplayed(true);
        } else {
            alert('You can now download your movie.');
        }
    };

    const AdSenseAd = () => {
        useEffect(() => {
            if (window.adsbygoogle) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('AdSense error', e);
                }
            }
        }, []);

        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1196895396100273"
                    data-ad-slot="4214645049"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
        );
    };

    

    return (
        <Container className="mt-4">
            <header style={headerStyle}>
                <h1 className="display-4" style={{ fontWeight: '900' }}>IMAX1</h1>
                <p
    className="lead"
    style={{
        fontFamily: 'Georgia, serif', // Example font family
        fontSize: '1rem',           // Adjust the font size as needed
        fontWeight: '400',            // Optional: Set font weight
        color: '#eee',                // Optional: Set font color
        textShadow: '1px 1px 4px #000' // Optional: Add shadow for emphasis
    }}
>
    Your Destination for Telugu Movies
</p>
            </header>


              <AdSenseAd></AdSenseAd>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" />
                    <p>Loading data, please wait...</p>
                </div>
            ) : error ? (
                <Alert variant="danger">{error}</Alert>
            ) : (
                <Row className="justify-content-center mb-4">
                    <Col xs={12} md={10} lg={8}>
                    <div style={movieNameStyles}>{movieName}</div>

                    <div style={{ position: 'relative', width: '100%', height: '250px' }}>
        
        <div
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.0)', // Semi-transparent background
                color: 'rgba(0, 0, 0, 0.0)',
                textAlign: 'center',
                padding: '15px',
                zIndex: 2, // Ensure banner is above iframe
            }}
        >
            🎬 Watch Now: Featured Movie Stream
        </div>

        {/* iFrame */}
        <iframe
            src={iframeSrc}
            style={{
                ...iframeStyles,
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
            }}
            title="Movie Stream"
            allowFullScreen
            scrolling="yes"
               onClick={handleFullScreenClick}
                  ></iframe>
                       </div>                    
                       <div
    style={downloadIframeContainerStyles}
    onClick={() => {
        if (!adDisplayed) {
            const adElement = document.querySelector('.adsbygoogle');
            if (adElement) {
                adElement.click(); // Simulate a user action on the ad
            }
            setAdDisplayed(true);
        } else {
            alert('You can now proceed to download your movie.');
        }
    }}
>
    <iframe
        src={downloadIframeSrc}
        style={downloadIframeStyles}
        title="Movie Download"
        scrolling="no"
    ></iframe>

    <div style={bannerStyles}>
        <div>Download Your IMAX1 Movie Here!</div>
    </div>
</div>
                        
                    </Col>
                </Row>
            )}
        
         
           <AdSenseAd></AdSenseAd>
        </Container>
    );
};

export default EntertainmentPage;
     
















