import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../../Firebase';
import { collection, getDocs } from 'firebase/firestore';
import _ from 'lodash'; // Import lodash for debouncing

export const TeluguOldYearWise = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [message, setMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [tasksPerPage] = useState(12); // Adjust this number to control items per page

    // Predefined list of years
    const yearsList = ['All', '1970', '1980', '1988', '1990', '2000', '2010'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tasksCollection = collection(db, 'teluguold');
                const snapshot = await getDocs(tasksCollection);
                const fetchedTasks = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setTasks(fetchedTasks);
            } catch (error) {
                setMessage('Failed to fetch data. Please try again later.');
            }
        };

        fetchData();
    }, []);

    const handleReadMoreClick = useCallback(
        (id) => {
            navigate(`/teluguoldyearwisepage/${id}`);
        },
        [navigate]
    );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Filtered tasks based on search query and selected year
    const [selectedYear, setSelectedYear] = useState('All');
    const filteredTasks = tasks.filter((task) => {
        const matchesSearchQuery = task.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesYear = selectedYear === 'All' || task.year === selectedYear;
        return matchesSearchQuery && matchesYear;
    });

    // Get current tasks for pagination
    const indexOfLastTask = currentPage * tasksPerPage;
    const indexOfFirstTask = indexOfLastTask - tasksPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);

    // Calculate total pages
    const totalPages = Math.ceil(filteredTasks.length / tasksPerPage);

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const headerStyle = {
        color: '#000',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
        marginTop: '-40px'
    };

    const AdSenseAd = () => {
        useEffect(() => {
            if (window.adsbygoogle) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('AdSense error', e);
                }
            }
        }, []);

        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1196895396100273"
                    data-ad-slot="4214645049"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
        );
    };

    return (
        <div className="container mt-4">
            <header className="container-fluid text-center py-4" style={headerStyle}>
                <h1 className="display-4 font-weight-bold text-light" style={{ fontWeight: '900' }}>IMAX1 THEATER</h1>
                <p
                    className="lead"
                    style={{
                        fontFamily: 'Georgia, serif',
                        fontSize: '1rem',
                        fontWeight: '400',
                        color: '#eee',
                        textShadow: '1px 1px 4px #000'
                    }}
                >   
                    Your Destination for Telugu Movies
                </p>
            </header>
          


         <AdSenseAd></AdSenseAd>


            <div className="filter-buttons mb-3" style={{ padding: '0px 0' }}>
    <Row className="gy-3 d-flex flex-nowrap">
  
     
      
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/teluguoldyearwise')} style={{ width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Telugu Old Year Wise
            </Button>
        </Col>
    
     
    </Row>
</div>

            <Form.Group className="mb-4">
                <Form.Control
                    type="text"
                    placeholder="Search for a movie..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </Form.Group>

            {/* Year Filter */}
            <Form.Group className="mb-4">
                <Form.Control as="select" value={selectedYear} onChange={handleYearChange}>
                    {yearsList.map((year, index) => (
                        <option key={index} value={year}>{year}</option>
                    ))}
                </Form.Control>
            </Form.Group>

            {/* Movies Display */}
            {currentTasks.length > 0 ? (
                <Row>
                    {currentTasks.map((task) => (
                        <Col key={task.id} xs={4} sm={4} md={3} lg={2} className="mb-4">
                            <div className="text-center">
                                <div
                                    className="imageView"
                                    style={{
                                        height: isMobile ? '150px' : '250px',
                                        width: '100%',
                                        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        margin: '0 auto',
                                    }}
                                    onClick={() => handleReadMoreClick(task.id)}
                                >
                                    <img
                                        src={task.url}
                                        alt={task.name}
                                        className="img-fluid"
                                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                        loading="lazy"
                                    />
                                </div>
                                <p className="mt-2 mb-1 text-truncate" style={{ fontSize: 'medium', color: 'blue', fontWeight: 'bold' }}>
                                    {task.name}
                                </p>
                                <Button
                                    onClick={() => handleReadMoreClick(task.id)}
                                    className="btn btn-sm"
                                    style={{ width: '100%', backgroundColor: 'gold', color: 'black', border: 'none', fontWeight: 'bold' }}
                                >
                                    IMAX1
                                </Button>
                            </div>
                        </Col>
                    ))}
                </Row>
            ) : (
                <p className="text-center">No movies available for this selection.</p>
            )}

<div
    className="pagination mt-4 text-center"
    style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px' }}
    >
    <Button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Previous
    </Button>
    {[...Array(totalPages)].map((_, index) => (
        <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            variant={currentPage === index + 1 ? "primary" : "outline-primary"}
            style={{ margin: '0 5px' }}
        >
            {index + 1}
        </Button>
    ))}
    <Button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Next
    </Button>
</div>



            <br />
            <AdSenseAd></AdSenseAd>
        </div>
    );
};
