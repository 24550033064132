import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Col, Row, Form, Spinner, Alert, Collapse } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { db } from '../../Firebase';
import { FaWhatsapp, FaFacebook, FaInstagram, FaTelegram } from 'react-icons/fa';
import { collection, getDocs, addDoc, orderBy, query, serverTimestamp } from 'firebase/firestore';
import './Entertainment.css';


export const Entertainment = () => {
    const navigate = useNavigate();
    const [iframeSrc, setIframeSrc] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [message, setMessage] = useState('');
    const [newMovie, setNewMovie] = useState({ name: '', description: '' });
    const [submittedRequests, setSubmittedRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    
   
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);
    const [expandedMovieId, setExpandedMovieId] = useState(null);
    const [open, setOpen] = useState(false);
    const [language, setLanguage] = useState('telugu'); // Default language

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const moviesPerPage = 20;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch tasks and order by createdAt timestamp in descending order
                const tasksCollection = collection(db, language);
                const tasksQuery = query(tasksCollection, orderBy('createdAt', 'desc'));
                const tasksSnapshot = await getDocs(tasksQuery);
                const fetchedTasks = tasksSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setTasks(fetchedTasks);
                
              

                // Fetch submitted requests without ordering
                const requestsCollection = collection(db, 'requests');
                const requestsSnapshot = await getDocs(requestsCollection);
                const fetchedRequests = requestsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setSubmittedRequests(fetchedRequests);
            } catch (error) {
                setMessage('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        const handleResize = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        window.matchMedia("(max-width: 768px)").addEventListener('change', handleResize);
        return () => {
            window.matchMedia("(max-width: 768px)").removeEventListener('change', handleResize);
        };
    }, [language]);

    

    const handleShare = (platform, id) => {
        const baseURL = window.location.origin; // e.g., https://yourwebsite.com
        const moviePath = `/entertainmentStage/${language}/${id}`;
        const shareLink = `${baseURL}${moviePath}`;
    
        if (platform === 'whatsapp') {
            window.open(
                `https://wa.me/?text=${encodeURIComponent(shareLink)}`,
                '_blank'
            );
        } else if (platform === 'facebook') {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`,
                '_blank'
            );
        } else if (platform === 'telegram') {
            window.open(
                `https://telegram.me/share/url?url=${encodeURIComponent(shareLink)}&text=${encodeURIComponent(
                    "Check out this movie!"
                )}`,
                '_blank'
            );
        } else if (platform === 'instagram') {
            alert("Instagram doesn't support direct link sharing. Please share the link manually.");
        }
    };
    
    




    const handleSearchChange = useCallback(
        _.debounce((value) => setSearchQuery(value), 300),
        []
    );
    

    const filteredMovies = useMemo(() => {
        return tasks.filter((task) => {
            const searchValue = searchQuery.toLowerCase();
            return (
                task.name.toLowerCase().includes(searchValue) || 
                (task.year && task.year.toString().includes(searchValue)) || 
                (task.actors && task.actors.toLowerCase().includes(searchValue))
            );
        });
    }, [tasks, searchQuery]);
    const handleLanguageChange = (newLanguage) => {
        setLanguage(newLanguage);
        setCurrentPage(1); // Reset to the first page
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMovie((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestsCollection = collection(db, 'requests');
            const newMovieData = {
                ...newMovie,
                createdAt: serverTimestamp(), // Add createdAt timestamp
            };
            const docRef = await addDoc(requestsCollection, newMovieData);
            setMessage('Movie request submitted successfully!');
            const newRequest = { id: docRef.id, ...newMovieData };
            setSubmittedRequests([newRequest, ...submittedRequests]);
            setNewMovie({ name: '', description: '' });
        } catch (error) {
            setMessage('Failed to submit the movie request. Please try again later.');
        }
    };

    const handleDetailsClick = (id, language) => {
        navigate(`/entertainmentStage/${language}/${id}`);
    };
    

    // Handle pagination
    const indexOfLastMovie = currentPage * moviesPerPage;
    const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
    const currentMovies = filteredMovies.slice(indexOfFirstMovie, indexOfLastMovie);

    const totalPages = Math.ceil(filteredMovies.length / moviesPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const headerStyle = {
        color: '#000',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
       
        color:'blue'
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const loadMoreMovies = async () => {
        try {
            const lastMovie = tasks[tasks.length - 1];
            const tasksCollection = collection(db, language);
            const tasksQuery = query(
                tasksCollection,
                orderBy('createdAt', 'desc'),
                startAfter(lastMovie.createdAt),
                limit(20)
            );
            const tasksSnapshot = await getDocs(tasksQuery);
            const fetchedTasks = tasksSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTasks([...tasks, ...fetchedTasks]);
        } catch (error) {
            console.error('Error loading more movies:', error);
        }
    };
    




    const AdSenseAd = () => {
        useEffect(() => {
            if (window.adsbygoogle) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('AdSense error', e);
                }
            }
        }, []);

        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1196895396100273"
                    data-ad-slot="4214645049"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
        );
    };



    const handleReadMoreClick = (id, link) => {
        setExpandedMovieId((prevId) => (prevId === id ? null : id));
        if (expandedMovieId !== id) {
            setIframeSrc(link); // Set the iframe source to the task's link
        }
    };
    
   
    return (
        <div className="container">
    
            <header className="container-fluid text-center py-4" style={headerStyle}>
                <h1 className="display-4 font-weight-bold text-light fontWeight-bold" style={{ fontWeight: '900' }}>IMAX1</h1>
                <p
    className="lead"
    style={{
        fontFamily: 'Georgia, serif', // Example font family
        fontSize: '1rem',           // Adjust the font size as needed
        fontWeight: '400',            // Optional: Set font weight
        color: '#eee',                // Optional: Set font color
        textShadow: '1px 1px 4px #000' // Optional: Add shadow for emphasis
    }}
>
    Your Destination for Telugu Movies
    <p>
  We do not support piracy. We will update the data few days after the official OTT release.
</p>

     </p>
            </header>

        


            <br></br>
            <div className="filter-buttons mb-3" style={{ padding: '0px 0', overflowX: 'auto' }}>
    <Row className="gy-3 d-flex flex-nowrap" style={{ minWidth: 'max-content' }}>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'telugu' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('telugu')}
                className="ml-2"
            >
                latest
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'telugudubbed' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('telugudubbed')}
                className="ml-2"
            >
                Dubbed
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'webseries' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('webseries')}
                className="ml-2"
            >
                WebSeries
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'shows' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('shows')}
                className="ml-2"
            >
                Shows
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'animation' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('animation')}
                className="ml-2"
            >
                Animation
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'teluguold' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('teluguold')}
                className="ml-2"
            >
                teluguold
            </Button>
        </Col>
      
      
      
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'requestedmovies' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('requestedmovies')}
                className="ml-2"
            >
               Requested Movies
            </Button>
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'classic' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('classic')}
                className="ml-2"
            >
                Classic
            </Button>
            
        </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
        <Button
                variant={language === 'english' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('english')}
                className="ml-2"
            >
                English
            </Button>
            </Col>
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button
                variant={language === 'documentary' ? 'primary' : 'secondary'}
                onClick={() => handleLanguageChange('documentary')}
                className="ml-2"
            >
                Documentry
            </Button>
        </Col>
    </Row>
</div>

        
<div className="mb-3">
    <Form.Control
        type="text"
        placeholder="Search for movies by name, year, or actor..."
        value={searchQuery}
        onChange={(e) => handleSearchChange(e.target.value)}
    />
</div>


<Row className="g-12">
    {loading ? (
        <Col className="text-center">
            <Spinner animation="border" role="status" />
            <p>Loading movies...</p>
        </Col>
    ) : currentMovies.length === 0 ? (
        <Col className="text-center">
            <Alert variant="info">No movies found for your search.</Alert>
        </Col>
    ) : (
        currentMovies.map((task, index) => (
            <React.Fragment key={task.id}>
                {/* Movie Card */}
                <Col xs={12} md={3} lg={3} className="mb-4">
                    <div className="d-flex flex-row align-items-center h-80">
                        {/* Left Image Section */}
                        <div
                            onClick={() => handleDetailsClick(task.id, language)}
                            style={{
                                flex: '0 0 30%',
                                height: isMobile ? '50px' : '150px', 
                              
                                width: '100%',
                                
                                borderRadius: '5px',
                                overflow: 'hidden',
                                cursor: 'pointer',
                            }}
                            className="mobile-height"
                        >
                            <img
                                className="img-fluid"
                                src={task.url || 'path/to/placeholder.jpg'}
                                alt={task.name}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                    
                                }}
                                loading="lazy"
                            />
                        </div>

                        {/* Right Data Section */}
                        <div
                            className="ps-3"
                            style={{
                                flex: '0 0 60%',
                                textAlign: 'left',
                                
                            }}
                        >
                            <p
                                className="mb-2 "
                                style={{
                                    fontSize: 'mediam',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '100%',
                                    lineHeight: '1', 
                                }}
                            >
                                <span style={{ fontWeight: '600',lineHeight: '1',  }} className="ellipsis">Movie</span> : {task.name}
                            </p>
                            <p className="mb-2 " style={{ fontSize: 'mediam', color: 'black', fontWeight: 'bold',lineHeight: '1',  }}>
                                <span style={{ fontWeight: '600' }} className="ellipsis">Year</span> : {task.year}
                            </p>
                            <p className="mb-2 " style={{ fontSize: 'mediam', color: 'black', fontWeight: 'bold',lineHeight: '1',  }}>
                                <span style={{ fontWeight: '600' }} className="ellipsis">Actors</span> : {task.actors}
                                </p>
                                <Button
                                onClick={() => handleReadMoreClick(task.id, task.link)}
                                className="btn btn-sm mt-2"
                                style={{
                                    backgroundColor: 'gold',
                                    color: 'black',
                                    border: 'none',
                                    fontWeight: 'bold',
                                    borderRadius: '5px',
                                    lineHeight: '1.5', 
                                }}
                            >
                                {expandedMovieId === task.id ? 'Close' : 'Open'}
                            </Button>
                          
                           
                          
                         
                        </div>
                    </div>
                </Col>

                {/* Full Width Collapse Section */}
                {expandedMovieId === task.id && (
                    <Col xs={12}>
                        <div
                            id={`collapseExample-${task.id}`}
                            style={{
                                marginTop: '10px',
                                width: '100%',
                                overflow: 'hidden',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: isMobile ? '200px' : '600px',
                                }}
                            >
                                {/* Banner Overlay */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.0)', // Semi-transparent background (darkened)
                                        color: 'rgba(0, 0, 0, 0.0)',
                                        textAlign: 'center',
                                        padding: '15px',
                                        zIndex: 2, // Ensure banner is above iframe
                                        fontSize: '15px', // Adjust text size
                                    }}
                                >
                                    <span style={{ backgroundColor: 'rgba(0, 0, 0, 0)', color: 'rgba(0, 0, 0, 0.0)' }}>
                                        🎬 Watch Now: Featured Movie Stream
                                    </span>
                                </div>

                                {/* Iframe Movie Stream */}
                                <iframe
                                    src={iframeSrc}
                                    title="Movie Stream"
                                    allowFullScreen
                                    scrolling="yes"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%', // Set iframe to fill the parent div
                                        border: 'none',
                                        zIndex: 1, // Ensure iframe is beneath the banner
                                    }}
                                ></iframe>
                            </div>
                        </div>
                       






  
 








                        <AdSenseAd />
                    </Col>
                )}
            </React.Fragment>
        ))
    )}
</Row>





     <div
    className="pagination mt-4 text-center"
    style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px' }}
    >
    <Button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Previous
    </Button>
    {[...Array(totalPages)].map((_, index) => (
        <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            variant={currentPage === index + 1 ? "primary" : "outline-primary"}
            style={{ margin: '0 5px' }}
        >
            {index + 1}
        </Button>
    ))}
    <Button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Next
    </Button>
</div>


         <AdSenseAd></AdSenseAd>
          
        </div>
    );
};
