import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import entertainment from '../assets/entertainment.png';

export const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  
  const handleClickOutside = (event) => {
   
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !event.target.closest('.menu-button')) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-logo">
        {/* Logo can go here */}
      </div>
      
      {/* Button for toggling sidebar */}
      <button
        className="menu-button"
        onClick={toggleSidebar}
        style={{
          display: 'flex',
          alignItems: 'center',
          border: 'none',
          backgroundColor: '#007bff',
          color: 'white',
          fontSize: '16px',
          cursor: 'pointer',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <span style={{ marginRight: '8px' }}>☰</span>
        IMAX1
      </button>

      {/* Sidebar */}
      <ul className={`nav-list ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
        <li className="nav-item">
          <NavLink className="nav-link" to="/">
            <img src={entertainment} alt="Entertainment" />
          </NavLink>
        </li>

        {/* Additional navigation items can be added here */}
      </ul>
    </div>
  );
};





