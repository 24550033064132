import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar } from './Navigation/Navbar';
import Footer from './Footer/Footer';
import { Entertainment } from './Pages/Home/Entertainment';
import { EntertainmentPage } from './Pages/Home/EntertainmentPage';
import { NotFound } from './NotFound';

import { Requstedmovies } from './Pages/Requstedmovies/Requstedmovies';
import { RequstedMoviesPage } from './Pages/Requstedmovies/RequstedMoviesPage';

import { TeluguOldYearWise } from './Pages/TeluguOldYearWise/TeluguOldYearWise';
import { TeluguOldYearWisePage } from './Pages/TeluguOldYearWise/TeluguOldYearWisePage';


const App = () => {
    return (
        <div>
            <Navbar />
            <div className="content">
         
                <Routes>
                    <Route path="/" element={<Entertainment />} />
                    <Route path="/entertainmentStage/:language/:id" element={<EntertainmentPage />} />                  
                    <Route path="/requstedmovies" element={<Requstedmovies />} />                     
                    <Route path="/teluguoldyearwise" element={<TeluguOldYearWise />} />
                    <Route path="/teluguoldyearwisepage/:id" element={<TeluguOldYearWisePage />} />                 
                    <Route path="/requastedmoviespage/:id" element={<RequstedMoviesPage />} />                   
                    <Route path="*" element={<NotFound />} /> {/* 404 Page */}
                </Routes>
              
            </div>
            <Footer />
        </div>
    );
};

export default App;
