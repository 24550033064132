import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Col, Row, Form, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { db } from '../../Firebase'; // Ensure this is correctly importing your Firebase setup
import { collection, getDocs } from 'firebase/firestore'; // Import Firestore functions
import _ from 'lodash'; // Import lodash for debouncing

export const Requstedmovies = () => {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [message, setMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);
    const [loading, setLoading] = useState(true); // To track loading state

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const itemsPerPage = 20; // Number of items per page

    // Fetch data from Firestore
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Start loading
            try {
                const tasksCollection = collection(db, 'requstedmovies'); // Firestore collection name
                const tasksSnapshot = await getDocs(tasksCollection);
                const fetchedTasks = tasksSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setTasks(fetchedTasks.reverse());
            } catch (error) {
                setMessage('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchData();
    }, []);

    // Debounce the search input to avoid excessive filtering on every keystroke
    const handleSearchChange = useMemo(
        () => _.debounce((value) => {
            setSearchTerm(value);
        }, 300),
        []
    );

    // Filter tasks based on search term
    const filteredTasks = tasks.filter((task) =>
        task.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);

    // Handle the Read More click for movie details
    const handleReadMoreClick = useCallback(
        (id) => {
            navigate(`/requastedmoviespage/${id}`);
        },
        [navigate]
    );
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    
    // Adjust layout based on screen size (mobile vs desktop)
    const handleResize = () => {
        setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Header style
    const headerStyle = {
        color: '#000',
        textAlign: 'center',
        padding: '2px 0',
        fontFamily: 'Arial, sans-serif',
        marginTop: '-40px'
    };

    // Handle page change
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    const AdSenseAd = () => {
        useEffect(() => {
            if (window.adsbygoogle) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                } catch (e) {
                    console.error('AdSense error', e);
                }
            }
        }, []);

        return (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1196895396100273"
                    data-ad-slot="4214645049"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
        );
    };
       

    return (
        <div className="container mt-4">
            <header className="container-fluid text-center py-4" style={headerStyle}>
                <h1 className="display-4 font-weight-bold text-light" style={{ fontWeight: '900' }}>IMAX1 THEATER</h1>
                <p
    className="lead"
    style={{
        fontFamily: 'Georgia, serif', // Example font family
        fontSize: '1rem',           // Adjust the font size as needed
        fontWeight: '400',            // Optional: Set font weight
        color: '#eee',                // Optional: Set font color
        textShadow: '1px 1px 4px #000' // Optional: Add shadow for emphasis
    }}
>
    Your Destination for Telugu Movies
</p>
      </header>
            <br />
            <div className="ads-container text-center mb-4">
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1196895396100273"
                    data-ad-slot="4214645049"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
            </div>
            <div className="filter-buttons mb-3" style={{ padding: '0px 0' }}>
    <Row className="gy-3 d-flex flex-nowrap">
  
        
        <Col className="d-flex justify-content-center" style={{ flex: 1 }}>
            <Button variant="primary" onClick={() => navigate('/requstedmovies')} style={{  width: '100%', paddingLeft: '10px', borderRadius: '50px',paddingRight:'10px' }}>
                Requested Movies
            </Button>
        </Col>
    </Row>
</div>

          
            <Form.Control
                type="text"
                placeholder="Search movies"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
                className="mb-4"
            />

            {/* Display Movies */}
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status" />
                    <p>Loading requested movies...</p>
                </div>
            ) : (
                <Row>
                    {currentTasks.length === 0 ? (
                        <Alert variant="info">{message || 'No movies found.'}</Alert>
                    ) : (
                        currentTasks.map((task) => (
                            <Col key={task.id} xs={4} sm={4} md={3} lg={2} className="mb-4">
                                <div className="text-center">
                                    <div
                                        className="imageView"
                                        style={{
                                            height: isMobile ? '150px' : '250px',
                                            width: '100%',
                                            boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            margin: '0 auto',
                                        }} 
                                        onClick={() => handleReadMoreClick(task.id)}
                                    >
                                        <img
                                            src={task.url}
                                            alt={task.name}
                                            className="img-fluid"
                                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                            loading="lazy"
                                        />
                                    </div>
                                    <p className="mt-2 mb-1 text-truncate" style={{ fontSize: 'medium', color: 'blue', fontWeight: 'bold' }}>
                                        {task.name}
                                    </p>
                                    <Button
                                        onClick={() => handleReadMoreClick(task.id)}
                                        className="btn btn-sm"
                                        style={{ width: '100%', backgroundColor: 'gold', color: 'black', border: 'none',fontWeight: 'bold' }}
                                    >
                                        IMAX1
                                    </Button>
                                </div>
                            </Col>
                        ))
                    )}
                </Row>
            )}

   <div
    className="pagination mt-4 text-center"
    style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px' }}
    >
    <Button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Previous
    </Button>
    {[...Array(totalPages)].map((_, index) => (
        <Button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            variant={currentPage === index + 1 ? "primary" : "outline-primary"}
            style={{ margin: '0 5px' }}
        >
            {index + 1}
        </Button>
    ))}
    <Button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        variant="outline-primary"
        style={{ margin: '0 5px' }}
    >
        Next
    </Button>
</div>
     <AdSenseAd></AdSenseAd>
              <br></br>
        </div>
    );
};        


