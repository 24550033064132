import React from 'react';
import { VisitorCount } from './VisitorCount';
import './Footer.css';

const Footer = () => {
    return (
        <footer
        className="footer container"
        style={{
            fontFamily: 'ScriptFont', // Specify the desired script font
            fontSize: '1rem', // Increase the font size as desired
            textAlign: 'center', // Center the text
            padding: '10px', // Add some padding
        
          
            borderRadius:'10px'
           
        }}
    >
       <p>
  We do not support piracy. We will update the data few days after the official OTT release.
</p>

        <VisitorCount />
    </footer>
    
    );
};

export default Footer;
